import React, { useState, useEffect } from "react";
import "./LoadingScreen.css";

const LoadingScreen = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [isLightVisible, setIsLightVisible] = useState(false);

  useEffect(() => {
    // Start animations when the component mounts
    setIsSpinning(true);
    setIsLightVisible(true);
  }, []);

  return (
    <div className="loading-screen">
      {/* Van container moves */}
      <div className="van-container">
        {/* Van body */}
        <img src="/images/van_body.png" alt="Van Body" className="van-body" />

        {/* Wheels locked to van */}
        <img
          src="/images/wheel_left.png"
          alt="Left Wheel"
          className={`wheel wheel-left ${isSpinning ? "spinning" : ""}`}
        />
        <img
          src="/images/wheel_right.png"
          alt="Right Wheel"
          className={`wheel wheel-right ${isSpinning ? "spinning" : ""}`}
        />

        {/* Lights aligned with van body */}
        <img
          src="/images/red_light.png"
          alt="Red Light"
          className={`light red-light ${isLightVisible ? "blinking" : ""}`}
        />
        <img
          src="/images/back_orange.png"
          alt="Back Orange Light"
          className={`light back-orange-light ${isLightVisible ? "blinking" : ""}`}
        />
        <img
          src="/images/front_orange.png"
          alt="Front Orange Light"
          className={`light front-orange-light ${isLightVisible ? "blinking" : ""}`}
        />
      </div>
    </div>
  );
};

export default LoadingScreen;