import React from "react";
import "./ProgressBar.css";

function ProgressBar({ title, value }) {
  return (
    <div className="progress-bar-container">
      <span className="progress-title">{`${title}: ${value}/5`}</span>
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${(value / 5) * 100}%` }}
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;