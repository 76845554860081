import React, { useEffect, useRef, useState } from "react";
import * as atlas from "azure-maps-control";
import ProgressBar from "./ProgressBar";
import LoadingScreen from "./LoadingScreen";
import "./App.css";

function App() {
  const mapRef = useRef(null);
  const panelRef = useRef(null);
  const [bakeries, setBakeries] = useState([]); // All bakeries
  const [selectedBakery, setSelectedBakery] = useState(null); // Selected bakery details
  const [isPanelVisible, setPanelVisible] = useState(false); // Panel visibility
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!loading && !mapRef.current) {
      const map = new atlas.Map("map", {
        center: [153.6, -28.7],
        zoom: 8,
        authOptions: {
          authType: "subscriptionKey",
          subscriptionKey: process.env.REACT_APP_AZURE_MAPS_KEY,
        },
        showFeedbackLink: false,
        showLogo: false,
      });

      map.events.add("ready", () => {
        const dataSource = new atlas.source.DataSource();
        map.sources.add(dataSource);

        map.imageSprite
          .add("bakery-icon", "/icons/Bakery.png")
          .then(() => {
            const symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
              iconOptions: {
                image: "bakery-icon",
                anchor: "center",
                allowOverlap: true,
                size: 0.05,
              },
            });
            map.layers.add(symbolLayer);

            // Fetch bakery data from backend
            fetch("https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/bakeries")
              .then((response) => response.json())
              .then((data) => {
                setBakeries(data);

                // Add bakery points to the map
                const features = data.map((bakery) =>
                  new atlas.data.Feature(
                    new atlas.data.Point([bakery.BakeryLong, bakery.BakeryLat]),
                    {
                      BakeryName: bakery.BakeryName,
                      BakeryID: bakery.BakeryID,
                    }
                  )
                );
                dataSource.add(features);
              })
              .catch((error) => console.error("Error fetching bakeries:", error));

            // Handle marker clicks
            map.events.add("click", symbolLayer, (e) => {
              if (e.shapes && e.shapes.length > 0) {
                const shape = e.shapes[0];
                const properties = shape.getProperties();
                handleBakeryClick(properties.BakeryID);
              }
            });
          })
          .catch((error) =>
            console.error("Error loading custom icon into image sprite:", error)
          );
      });

      mapRef.current = map;
    }

    const handleOutsideClick = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setPanelVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [loading]);

  const handleBakeryClick = (bakeryId) => {
    // Fetch bakery details first
    fetch(`https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/bakery/${bakeryId}`)
      .then((response) => response.json())
      .then((data) => {
        setSelectedBakery({ ...data, BakeryImages: [], Pies: data.Pies.map(pie => ({ ...pie, PiePictures: [] })) });
        setPanelVisible(true);

        // Fetch images separately
        fetch(`https://backend-piemap-azddbce4crgjgchz.australiaeast-01.azurewebsites.net/api/bakery/${bakeryId}/images`)
          .then((response) => response.json())
          .then((imageData) => {
            setSelectedBakery((prev) => ({
              ...prev,
              BakeryImages: imageData.BakeryImages.map(
                (img) => `data:image/jpeg;base64,${img}`
              ),
              Pies: prev.Pies.map((pie) => ({
                ...pie,
                PiePictures: imageData.PieImages.find((p) => p.PieID === pie.PieID)
                  ?.Images.map((img) => `data:image/jpeg;base64,${img}`) || [],
              })),
            }));
          })
          .catch((error) => console.error("Error fetching bakery images:", error));
      })
      .catch((error) => console.error("Error fetching bakery details:", error));
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div id="map" style={{ width: "100vw", height: "100vh" }}></div>

      {/* Render the panel */}
      {isPanelVisible && (
        <div ref={panelRef} className="panel visible">
          {selectedBakery && (
            <div className="bakery-container">
              {/* Bakery details */}
              <h1 className="bakery-name">{selectedBakery.BakeryName}</h1>
              <p className="bakery-address">
                {selectedBakery.BakeryAddress || "N/A"}
              </p>
              <p>Tomato Sauce: {selectedBakery.TomatoSauce || "N/A"}</p>

              {/* Bakery Images */}
              <div className="horizontal-scroll">
                {selectedBakery.BakeryImages.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Bakery image ${index + 1}`}
                    className="bakery-image"
                  />
                ))}
              </div>

              {/* Pies */}
              <h2>Pies</h2>
              <div className="pies-list">
                {selectedBakery.Pies.map((pie, index) => (
                  <div key={index} className="pie-item">
                    <h3 className="pie-name">{pie.PieName}</h3>
                    <p className="pie-price">Price: ${pie.PiePrice}</p>

                    {/* Pie Images */}
                    <div className="horizontal-scroll">
                      {pie.PiePictures.map((url, index) => (
                        <img
                          key={index}
                          src={url}
                          alt={`Pie image ${index + 1}`}
                          className="pie-image"
                        />
                      ))}
                    </div>

                    {/* Pie Progress Bars */}
                    <ProgressBar title="Filling Generosity" value={pie.FillingGenerosity} />
                    <ProgressBar title="Value for Money" value={pie.ValueForMoney} />
                    <ProgressBar title="Presentation" value={pie.Presentation} />
                    <ProgressBar title="Burned Mouth Factor" value={pie.BurnedMouthFactor} />
                    <ProgressBar title="Hunger Satisfaction" value={pie.HungerSatisfaction} />
                    <ProgressBar title="Sogginess" value={pie.Sogginess} />
                    <ProgressBar title="Eating Difficulty" value={pie.EatingDifficulty} />
                    <ProgressBar title="Exoticness" value={pie.Exoticness} />

                    <p className="pie-comments">Comments: {pie.PieComments}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;